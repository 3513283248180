import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
// window.BACKEND_IP="http://127.0.0.1/chat_app_backend/public/api/"
// window.BACKEND_IP = "https://scorpionadmin.emeraldbay.lk/api/"
window.BACKEND_IP = "https://wickhomescorpion.com/public/api/"
// window.BACKEND_IP = "https://emeraldbay.gaminitex.com/public/api/"
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWsebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
