import {
  CameraOutlined,
  DollarOutlined,
  MessageOutlined,
  SettingOutlined,
  UnlockOutlined, UserAddOutlined
} from '@ant-design/icons';
import { Col, Radio, Row } from "antd";
import { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
const Layout = () => {

  const nav = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("token") == null) {
      nav("/");
    }
  }, [sessionStorage.getItem("token")]);
  return (
    <>
      <nav>
        <Row gutter={24} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Radio.Group>
              {sessionStorage.getItem("IS_SUPER_ADMIN") == 0 ?
                <Radio.Button value="button2" style={{
                  fontSize: "15px",
                  height: "55px",
                  width: "80px",
                  margin: "5px",
                  backgroundColor: "#ffe44f"
                }} >
                  <Link to="/message"><CameraOutlined style={{ fontSize: "50px" }} /></Link></Radio.Button>
                : <Radio.Button value="button2" style={{
                  fontSize: "15px",
                  height: "55px",
                  width: "80px",
                  margin: "5px",
                  backgroundColor: "#ffe44f"
                }} >
                  <Link to="/message-view"><MessageOutlined style={{ fontSize: "50px" }} /></Link></Radio.Button>
              }
              {sessionStorage.getItem("IS_SUPER_ADMIN") == 1 ?
                <Radio.Button value="button2" style={{
                  fontSize: "15px",
                  height: "55px",
                  width: "80px",
                  margin: "5px",
                  backgroundColor: "rgb(145 227 103)"
                }} ><Link to="/users"><UserAddOutlined style={{ fontSize: "50px" }} /></Link></Radio.Button>
                : ""}
              {sessionStorage.getItem("IS_SUPER_ADMIN") == 1 ?
                <Radio.Button value="button2" style={{
                  fontSize: "15px",
                  height: "55px",
                  width: "80px",
                  margin: "5px",
                  backgroundColor: "rgb(110 225 219 / 45%)"
                }} ><Link to="/setting"><SettingOutlined style={{ fontSize: "50px" }} /></Link></Radio.Button>
                : ""}

              {sessionStorage.getItem("IS_SUPER_ADMIN") == 0 ?
                <Radio.Button value="button2" style={{
                  fontSize: "15px",
                  height: "55px",
                  width: "80px",
                  margin: "5px",
                  backgroundColor: "rgb(145 227 103)"
                }} ><Link to="/today-invest"><DollarOutlined style={{ fontSize: "50px" }} /></Link></Radio.Button>
                : ""}

              <Radio.Button value="button2" style={{
                fontSize: "15px",
                height: "55px",
                width: "80px",
                margin: "5px",
                backgroundColor: "rgb(255 148 148)"
              }}
                onClick={() => {
                  sessionStorage.clear();
                  nav("/");
                }}
              > <UnlockOutlined style={{ fontSize: "50px" }} /> </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </nav >

      <Outlet />
    </>
  )
};

export default Layout;