import {
  CopyOutlined,
  DollarOutlined,
  DownloadOutlined,
  MailOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Row, Select } from 'antd';
import axios from 'axios';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import './Chat.css';

const Option = Select.Option;
const MessageView = () => {
  const [userDetail, setUserDetail] = useState({});
  const [users, setUsers] = useState([]);
  const [showChit, setShowChit] = useState(false);
  const [userImages, setUserImages] = useState([]);
  const messagesEndRef = useRef(null)

  const [loading, setLoading] = useState(false);

  const getChits = async (type) => {
    try {
      setLoading(true)
      const response = await axios.post(window.BACKEND_IP + "get/today/messages/by/user", {
        USER_ID: userDetail.USER_ID
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setUserImages([])
      var morningTimeEnd = parseInt(response.data.setting.MORNING_HOUR_END * 60) + parseInt(response.data.setting.MORNING_MINUTES_END)
      var eveningTimeEnd = parseInt(response.data.setting.EVENING_HOUR_END * 60) + parseInt(response.data.setting.EVENIN_MINUTES_END)

      var ar = [];
      for (let i = 0; i < response.data.rows.length; i++) {
        var createdDateTime = parseInt(moment(response.data.rows[i].CREATED_DATE).format("H") * 60) + parseInt(moment(response.data.rows[i].CREATED_DATE).format("m"))
        if (response.data.rows[i].EX == 0 && type == "MORNING" && createdDateTime < morningTimeEnd) {
          response.data.rows[i].LATE = false
          ar.push(response.data.rows[i])
        }
        if (response.data.rows[i].EX == 0 && type == "EVENING" && createdDateTime > morningTimeEnd) {
          response.data.rows[i].LATE = createdDateTime > eveningTimeEnd ? true : false;
          ar.push(response.data.rows[i])
        }
        if (response.data.rows[i].EX == 1 && type == "EX") {
          response.data.rows[i].LATE = false
          ar.push(response.data.rows[i])
        }
      }
      setUserImages(ar)
    } catch (error) {
      console.error('Error:', error);
      // Handle errors, such as setting an error state or displaying an error message
    } finally {
      setLoading(false)
    }
  }
  const downloadOnChange = async (value) => {
    if (value == 2) {
      window.open(window.BACKEND_IP + "save/images/" + userDetail.USER_ID + "/ALL", '_blank');
    } else if (value == 3) {
      window.open(window.BACKEND_IP + "save/images/" + userDetail.USER_ID + "/MORNING", '_blank');
    } else if (value == 4) {
      window.open(window.BACKEND_IP + "save/images/" + userDetail.USER_ID + "/EVENING", '_blank');
    } else if (value == 5) {
      window.open(window.BACKEND_IP + "save/images/" + userDetail.USER_ID + "/EX", '_blank');
    }
  }
  const getMessages = async (e) => {
    try {
      setLoading(true)
      const response = await axios.get(window.BACKEND_IP + "get/messages", {
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setUsers(response.data.rows)
    } catch (error) {
      console.error('Error:', error);
      // Handle errors, such as setting an error state or displaying an error message
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMessages()
  }, []);

  // useEffect(() => {
  //   scrollToBottom();
  // }, [myImages]);


  return (
    <div className="chat-container">
      <Card loading={loading} className='cardPadding10px'>
        {!showChit ?
          <div className="chat-messages" >
            {users.map((user, index) => (
              <div key={index} className={`message `} style={{ borderRadius: "0px 45px", fontSize: "16px", marginLeft: "-5px", border: "3px solid #f3f3f3", background: "linear-gradient(134deg, rgb(125 171 191 / 72%) 0%, rgb(175 248 255 / 66%) 59%, rgb(234 171 182 / 72%) 100%)" }}>
                <Row>
                  <Col span={24} onClick={() => {
                    setUserDetail(user)
                    setShowChit(true)
                    setUserImages([])
                  }}>
                    {user.UNSEEN_IMAGES > 0 ?
                      <p style={{
                        position: "absolute",
                        right: 0,
                        marginTop: "-10px",
                        fontSize: "25px",
                        color: "red",
                        fontWeight: "bold",
                        color: "black"
                      }}> <MailOutlined /> <span style={{
                        position: "absolute",
                        marginTop: "-0px",
                        float: "right",
                        right: "-5px",
                        color: "red",
                        fontSize: "11px",
                        border: "1px solid #f09696",
                        background: "white",
                        width: "16px",
                        height: "16px",
                        borderRadius: "100px"
                      }}>{user.UNSEEN_IMAGES}</span></p>
                      : ""}
                    <p style={{
                      textAlign: "left",
                      fontSize: "16x",
                      fontWeight: "600",
                      letterSpacing: "2px",
                      margin: "1px 1px"
                    }}>{user.NAME} </p>
                    <p style={{ textAlign: "left", marginBottom: "1px" }}><b>Cash :</b> {user.CASH_INVEST ? parseFloat(user.CASH_INVEST).toFixed(2) : "0"} <span style={{ float: "right" }}>{user.LOCATION}</span></p>
                    <p style={{ textAlign: "left", margin: "1px" }}><b>Morning :</b> {user.MORNING_INVEST ? parseFloat(user.MORNING_INVEST).toFixed(2) : "0"} <span style={{ float: "right" }}><span style={{ color: "#012c95b8", fontWeight: "bold" }} >MO:  {user.MORNING_COUNT} | EV:  {user.EVENING_COUNT} | EX:  {user.EX_COUNT} </span><CopyOutlined style={{ fontSize: "18px" }} /> </span></p>
                    <p style={{ textAlign: "left", margin: "1px" }}><b>Evening :</b> {user.EVENING_INVEST ? parseFloat(user.EVENING_INVEST).toFixed(2) : "0"} <span style={{ float: "right" }}><b>Chit Total :</b> {user.CHIT_TOTAL ? parseFloat(user.CHIT_TOTAL).toFixed(2) : "0"} <DollarOutlined style={{ fontSize: "18px" }} /> </span></p>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
          :
          <div>
            <h3 style={{ textAlign: "left" }} >Message List <Button style={{ float: "right" }} onClick={() => {
              setShowChit(false)
              getMessages();
            }}>Back</Button></h3>
            <p>{userDetail.NAME}, {userDetail.CONTACT_NO}, {userDetail.LOCATION} </p>
            <hr />
            <Row>
              <Col span={24}>
                <p style={{ margin: "1px", textAlign: "left" }}>Download</p>
                <Row>
                  <Col span={12}>
                    <Button onClick={() => downloadOnChange("3")} style={{ width: "100%", backgroundColor: "rgb(228 255 220)", fontSize: "20px", fontWeight: "bolder", height: "40px" }}>Morning <DownloadOutlined /></Button>
                  </Col>
                  <Col span={12}>
                    <Button onClick={() => downloadOnChange("4")} style={{ width: "100%", backgroundColor: "rgb(228 255 220)", fontSize: "20px", fontWeight: "bolder", height: "40px" }}>Evening <DownloadOutlined /></Button>
                  </Col>
                  <Col span={12}>
                    <Button onClick={() => downloadOnChange("5")} style={{ width: "100%", backgroundColor: "rgb(228 255 220)", fontSize: "20px", fontWeight: "bolder", height: "40px" }}>Ex <DownloadOutlined /></Button>
                  </Col>
                  <Col span={12}>
                    <Button onClick={() => downloadOnChange("2")} style={{ width: "100%", backgroundColor: "rgb(228 255 220)", fontSize: "20px", fontWeight: "bolder", height: "40px" }}>All <DownloadOutlined /></Button>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Card style={{ margin: "5px" }} className="cardPadding5px">
                  <Row>
                    <Col span={24}>
                      <Button onClick={() => getChits("MORNING")} style={{ width: "100%", backgroundColor: "#b0fba5", fontSize: "14px", height: "40px" }}>Morning
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={8}>
                <Card style={{ margin: "5px" }} className="cardPadding5px">
                  <Row>
                    <Col span={24}>
                      <Button onClick={() => getChits("EVENING")} style={{ width: "100%", backgroundColor: "#fbf3a5", fontSize: "20px", height: "40px" }}>Evening    {/* <EyeOutlined /> */}</Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={8}>
                <Card style={{ margin: "5px" }} className="cardPadding5px">
                  <Row>
                    <Col span={24}>
                      <Button onClick={() => getChits("EX")} style={{ width: "100%", backgroundColor: "rgb(219 180 255)", fontSize: "20px", height: "40px" }}>EX </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              {userImages.map((images, index) => (
                <Col span={8} key={index} style={{ border: images.LATE == true ? "1px solid rgb(193 68 68)" : "3px solid #f3f3f3" }}>
                  <img src={images.IMAGE} style={{ width: "100%" }} className="imageObjectFit" />
                  <Row>
                    <Col span={24}>
                      <p style={{ textAlign: "center", fontSize: "10px", margin: "0" }}>{moment(images.CREATED_DATE).format("Y-MM-DD LTS")}</p>
                    </Col>
                    <Col span={24}>
                      <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}><b>Inv :</b> {parseFloat(images.INVESTMENT).toFixed(2)}</p>
                    </Col>
                  </Row>

                  <div ref={messagesEndRef} />
                </Col>
              ))}
            </Row>
          </div>
        }
      </Card >
    </div >
  );
};

export default MessageView;