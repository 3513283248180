import { HashRouter as BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Layout from "./Layout";
import Login from "./Login";
import MessageView from "./MessageView";
import SendImages from "./SendImages";
import Setting from "./Setting";
import TodayInvest from "./TodayInvest";
import UserComponent from "./UserComponent";

function App() {


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}>
            <Route index element={<Login />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route path="/message" element={<SendImages />} />
            <Route path="/users" element={<UserComponent />} />
            <Route path="/message-view" element={<MessageView />} />
            <Route path="/today-invest" element={<TodayInvest />} />
            <Route path="/setting" element={<Setting />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
