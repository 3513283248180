// import './App.css';

import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Table, message } from "antd";
import Password from 'antd/es/input/Password';
import axios from "axios";
import { useEffect, useState } from "react";

const UserComponent = (props) => {
  const [visibility, setVisibility] = useState(false);
  const [userUpdate, setUserUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userUpdateID, setUserUpdateID] = useState(null);
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'NAME',
    },
    {
      title: 'User Name',
      dataIndex: 'CONTACT_NO',
    }, {
      title: 'Location',
      dataIndex: 'LOCATION',
    }, {
      title: 'Edit',
      align: "right",
      render: (data) => (
        <span>
          <Button size="small" onClick={() => editUser(data)}  ><EditOutlined /></Button>
        </span>
      )
    }]

  const editUser = async (data) => {
    setLoading(true)
    setVisibility(true)
    try {
      form.setFieldValue(["user", "name"], data.NAME)
      form.setFieldValue(["user", "password"], data.PASSWORD)
      form.setFieldValue(["user", "location"], data.LOCATION)
      form.setFieldValue(["user", "contact"], data.CONTACT_NO)
      setUserUpdate(true)
      setUserUpdateID(data.USER_ID)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const getUsers = async () => {
    setLoading(true)
    try {
      await axios
        .get(`${window.BACKEND_IP}all/user`, [])
        .then((response) => {
          setUsers(response.data)
        })
        .catch((error) => { });
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  const submitForm = async (values) => {
    setLoading(true)
    try {
      var url = `${window.BACKEND_IP}user/save`
      if (userUpdate) {
        url = `${window.BACKEND_IP}user/update`;
        values.user.updateId = userUpdateID;
      }
      await axios
        .post(url, values.user)
        .then((response) => {
          if (response.data) {
            form.resetFields();
            messageApi.open({
              type: 'success',
              content: 'User Saved Success!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          } else {
            messageApi.open({
              type: 'warning',
              content: 'User Name Already Exits!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
    } finally {
      setLoading(false)
    }
  };

  const newUser = () => {
    setUserUpdate(false);
    setVisibility(true)
  }
  useEffect(() => {
    getUsers();
  }, [])
  return (
    <div>
      <Divider style={{ margin: "10px 0" }} />
      {!visibility ?
        <Row>
          <Col span={14}>
            <p style={{
              textAlign: 'left',
              marginLeft: "5px",
              marginTop: "-2px",
              fontSize: "25px",
              marginBottom: "0px"
            }}>Users</p>
          </Col>
          <Col span={10}>
            <Button type="primary" onClick={() => newUser()}>New</Button>
          </Col>
        </Row> :
        <Button onClick={() => {
          setVisibility(false);
          getUsers();
          form.resetFields();
        }} type="primary" style={{ width: "80%", textAlign: "center", backgroundColor: "#869986" }}>
          Cancel
        </Button>
      }
      <Divider style={{ margin: "10px 0" }} />
      {contextHolder}
      {!visibility ?
        <Table pagination={false} size="small" loading={loading} columns={columns} dataSource={users} />
        :
        <Form
          form={form}
          name="form_item_path" layout="vertical" onFinish={submitForm} style={{ margin: "10px" }}>
          <Row gutter={24}>
            <Col span={24} className="marginBottom-15px"  >
              <Form.Item name={['user', 'name']} label="Name" rules={[{ required: true }]}>
                <Input className="fontSize20" placeholder='ex: Sahan Chamara' />
              </Form.Item>
            </Col>
            <Col span={24} className="marginBottom-15px">
              <Form.Item name={['user', 'contact']} label="User Name" rules={[{ required: true }]}>
                <Input className="fontSize20" placeholder='ex: sahan93' />
              </Form.Item>
            </Col>
            <Col span={24} className="marginBottom-15px">
              <Form.Item name={['user', 'password']} label="Password" rules={[{ required: true }]}>
                <Password className="fontSize20" placeholder='Type your password here' />
              </Form.Item>
            </Col>
            <Col span={24} className="marginBottom-15px">
              <Form.Item name={['user', 'location']} label="Location">
                <Input className="fontSize20" placeholder='ex: Kalutara ' />
              </Form.Item>
            </Col>
            <Col span={24} className="marginBottom-15px">
              <Button loading={loading} type="primary" htmlType="submit" style={{ width: "80%", textAlign: "center", backgroundColor: "#188f18" }}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      }
    </div >
  );
}

export default UserComponent;
