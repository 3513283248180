import { Button, Col, Divider, Form, Row, Select, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
const Setting = (props) => {
  const [loading, setLoading] = useState(false);
  const [timeHValue, setTimeHValue] = useState([]);
  const [timeMValue, setTimeMValue] = useState([]);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const getSettings = async () => {
    setLoading(true)
    var timeHValue1 = [];
    var timeMValue1 = [];
    for (let i = 0; i < 24; i++) {
      timeHValue1.push(i)
    }
    setTimeHValue(timeHValue1)

    for (let i = 0; i < 60; i++) {
      timeMValue1.push(i)
    }
    setTimeMValue(timeMValue1)
    try {
      await axios
        .get(`${window.BACKEND_IP}get/setting`, [])
        .then((response) => {
          form.setFieldValue(["setting", "morningTimeEndH"], response.data.rows.MORNING_HOUR_END)
          form.setFieldValue(["setting", "morningTimeEndM"], response.data.rows.MORNING_MINUTES_END)
          form.setFieldValue(["setting", "eveningTimeEndH"], response.data.rows.EVENING_HOUR_END)
          form.setFieldValue(["setting", "eveningTimeEndM"], response.data.rows.EVENIN_MINUTES_END)
        })
        .catch((error) => { });
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  const submitForm = async (values) => {
    setLoading(true)
    try {
      var url = `${window.BACKEND_IP}setting/save`
      values.setting.USER_ID = sessionStorage.getItem("USER_ID");
      await axios
        .post(url, values.setting)
        .then((response) => {
          if (response.data) {
            messageApi.open({
              type: 'success',
              content: 'Saved Success!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    getSettings();
  }, [])
  return (
    <div>
      <Divider style={{ margin: "10px 0" }} />
      <Row>
        <Col span={14}>
          <p style={{
            textAlign: 'left',
            marginLeft: "5px",
            marginTop: "-2px",
            fontSize: "25px",
            marginBottom: "0px"
          }}>Setting</p>
        </Col>
      </Row>
      <Divider style={{ margin: "10px 0" }} />
      {contextHolder}
      <Form
        form={form}
        name="form_item_path" layout="vertical" onFinish={submitForm} style={{ margin: "10px" }}>
        <Row gutter={24}>
          <Col span={24} style={{ marginBottom: "15px" }}>
            <p>Morning Time End</p>
          </Col>
          <Col span={12} style={{ marginBottom: "15px" }}>
            <Form.Item name={['setting', 'morningTimeEndH']} label="Hours">
              <Select>
                {timeHValue.map((value, i) =>
                  <Select.Option key={i} value={value}>{value}</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginBottom: "15px" }}>
            <Form.Item name={['setting', 'morningTimeEndM']} label="Minutes">
              <Select>
                {timeMValue.map((value, i) =>
                  <Select.Option key={i} value={value}>{value}</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ marginBottom: "15px" }}>
            <p>Evening Time End</p>
          </Col>
          <Col span={12} style={{ marginBottom: "15px" }}>
            <Form.Item name={['setting', 'eveningTimeEndH']} label="Hours">
              <Select>
                {timeHValue.map(value =>
                  <Select.Option key={value} value={value}>{value}</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginBottom: "15px" }}>
            <Form.Item name={['setting', 'eveningTimeEndM']} label="Minutes">
              <Select>
                {timeMValue.map(value =>
                  <Select.Option key={value} value={value}>{value}</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ marginBottom: "15px" }}>
            <Button loading={loading} type="primary" htmlType="submit" style={{ width: "100%", textAlign: "center", backgroundColor: "#188f18", marginTop: "30px" }}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div >
  );
}

export default Setting;
