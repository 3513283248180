import {
  CameraOutlined,
  SendOutlined, SyncOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Modal, Row, message } from 'antd';
import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
const TakeAPhoto = (props) => {
  const webcamRef = useRef(null);
  const [isCapture, setIsCapture] = useState(false);
  const [imageSrc, setImageSrc] = useState([]);
  const [facingMode, setFacingMode] = useState('environment'); // 'user' for front camera, 'environment' for back camera
  const [form] = Form.useForm();
  const [checked, setChecked] = useState([]);

  const videoConstraints = {
    facingMode: facingMode,
  };

  const imageValueUpdate = (val) => {
    var img = imageSrc;
    img.push(val)
    setImageSrc(img)
  }
  const capture = useCallback(async () => {
    const imageSrc1 = webcamRef.current.getScreenshot();
    // const reducedQualityImage = await reduceImageQuality(imageSrc1, 0.1);
    var watermarkedBase64 = await addWatermarkToBase64(imageSrc1);
    imageValueUpdate(watermarkedBase64);

    setIsCapture(true);
  }, [webcamRef]);

  const reduceImageQuality = async (imageSrc, quality) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        // Reduce image quality by drawing it onto canvas with reduced quality
        canvas.toBlob(blob => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        }, 'image/jpeg', quality);
      };
      img.src = imageSrc;
    });
  };
  function addWatermarkToBase64(base64Image) {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it starts from 0
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${day} / ${hours}:${minutes}:${seconds}`;
    var watermarkText = formattedDateTime
    return new Promise((resolve, reject) => {
      // Create image object
      const img = new Image();
      img.onload = function () {
          // Create canvas
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Set canvas dimensions same as image
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Define watermark text
          // Calculate text width and height
          const fontSize = 16;
          const fontFamily = 'Arial';
          ctx.font = `${fontSize}px ${fontFamily}`;
          const textWidth = ctx.measureText(watermarkText).width;
          const textHeight = fontSize;

          // Define watermark box properties
          const boxPadding = 5; // Padding around the text
          const boxWidth = textWidth + 2 * boxPadding; // Box width including padding
          const boxHeight = textHeight + 2 * boxPadding; // Box height including padding
          const boxX = 10; // X-coordinate of the box
          const boxY = canvas.height - boxHeight - 10; // Y-coordinate of the box, adjust as needed

          // Draw black background square box
          ctx.fillStyle = 'black';
          ctx.fillRect(boxX, boxY, boxWidth, boxHeight);

          // Draw watermark text in white
          ctx.fillStyle = 'white';
          ctx.fillText(watermarkText, boxX + boxPadding, boxY + boxHeight - boxPadding);

          // Convert canvas to base64 data URL
          const watermarkedBase64 = canvas.toDataURL('image/jpeg');

          // Resolve with watermarked base64 image
          resolve(watermarkedBase64);
      };

      // Set image source to base64 data
      img.src = base64Image;
  });

  }
  const onChange = (e) => {
    var ar = checked;
    ar.push(e.target.checked ? 1 : 0);
    setChecked(ar);
  };

  // const uploadImage = useCallback(async () => {
  //   try {
  //     await axios.post(window.BACKEND_IP + "images/save", {
  //       USER_ID: sessionStorage.getItem("USER_ID"),
  //       NAME: sessionStorage.getItem("NAME"),
  //       INVESTMENT: form.getFieldValue("investment") ? form.getFieldValue("investment") : 0,
  //       EX: checked.length == 0 ? 0 : checked[checked.length - 1],
  //       IMAGE: imageSrc[imageSrc.length - 1]
  //     }, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     setIsCapture(false);
  //     form.resetFields();
  //     var ar = checked;
  //     ar.push(0);
  //     setChecked(ar);
  //     props.getImages();
  //     message.success("Image save success")
  //   } catch (error) {
  //     console.error('Error:', error);
  //     // Handle errors, such as setting an error state or displaying an error message
  //   }

  // }, [webcamRef]);
const uploadImage = useCallback(async () => {
  try {
    const formData = new FormData();
    formData.append("USER_ID", sessionStorage.getItem("USER_ID"));
    formData.append("NAME", sessionStorage.getItem("NAME"));
    formData.append("INVESTMENT", form.getFieldValue("investment") ? form.getFieldValue("investment") : 0);
    formData.append("EX", checked.length == 0 ? 0 : checked[checked.length - 1]);

    const base64String = imageSrc[imageSrc.length - 1];
    const blob = await fetch(base64String).then(res => res.blob());
    formData.append("IMAGE", blob, "photo.jpg");

    await axios.post(window.BACKEND_IP + "images/save", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    setIsCapture(false);
    form.resetFields();
    var ar = checked;
    ar.push(0);
    setChecked(ar);
    props.getImages();
    message.success("Image save success");
  } catch (error) {
    console.error('Error:', error);
    // Handle errors, such as setting an error state or displaying an error message
  }
}, [webcamRef, imageSrc, checked, form]);

  return (
    <Modal
      title="Take a photo"
      open={props.visible}
      onCancel={props.cancelModel}
      footer={[
        <Button key="back" onClick={props.cancelModel}>
          Cancel
        </Button>]}
    >
      {/* <label>
        <input
          type="radio"
          name="facingMode"
          value="user"
          checked={facingMode === 'user'}
          onChange={() => setFacingMode('user')}
        />
        Front Camera
      </label>
      <label>
        <input
          type="radio"
          name="facingMode"
          value="environment"
          checked={facingMode === 'environment'}
          onChange={() => setFacingMode('environment')}
        />
        Back Camera
      </label>
      */}
      {isCapture ?
        <Form
          form={form}
        >
          <Row gutter={24}>
            <Col span={14} style={{
              paddingLeft: "5px",
              paddingRight: "10px"
            }}>
              <img src={imageSrc[imageSrc.length - 1]}
                style={{
                  height: "230px",
                  objectFit: "cover"
                }}
                width={"100%"}
              />
            </Col>
            <Col span={10}>
              <Row gutter={24} className='padding0px'>
                <Col span={24} className='padding0px'>

                  <label><span style={{ color: "red" }}>*</span> Invest</label>
                  <Form.Item name={['investment']} rules={[{ required: true }]}>
                    <Input type="tel" className="fontSize20" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={8} className='padding0px'>
                  <Form.Item name='ex' >
                    <label>Ex</label>
                    <Checkbox onChange={onChange} className="fontSize20" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={15}>
                  <Button
                    style={{
                      backgroundColor: "rgb(255 172 106 / 49%)",
                      fontSize: "15px",
                      fontWeight: "bold",
                      width: "100%",
                      height: "60px",
                      marginTop: "23px",
                      marginLeft: "18px"
                    }}
                    onClick={() => {
                      setIsCapture(false)
                    }}><SyncOutlined style={{ fontSize: '15px', marginLeft: "-5px" }} /></Button>
                </Col>
                <Col span={24}>
                  <Button style={{
                    width: "100%",
                    backgroundColor: "rgb(135 202 174)",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    height: "70px"
                  }}
                    onClick={uploadImage}><SendOutlined style={{ fontSize: '40px' }} /></Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        :
        <Row gutter={24}>
          <Col span={24}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              width="100%"
            // height={480}
            />
          </Col>
          <Col span={24}>
            <Button style={{
              width: "100%",
              backgroundColor: "#318d67",
              fontSize: "15px",
              fontWeight: "bold",
              letterSpacing: "2px",
              height: "70px"
            }} onClick={capture}><CameraOutlined style={{ fontSize: '50px' }} /></Button>
          </Col>
        </Row>
      }
    </Modal >
  );
};

export default TakeAPhoto;