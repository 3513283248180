import { Button, Col, Divider, Form, Input, Row, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

const TodayInvest = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const getInvests = async () => {
    setLoading(true)
    try {
      await axios
        .get(`${window.BACKEND_IP}get/today/invest/` + sessionStorage.getItem("USER_ID"), [])
        .then((response) => {
          form.setFieldValue(["invest", "cashInvest"], response.data.rows.CASH_INVEST)
          form.setFieldValue(["invest", "morningInvest"], response.data.rows.MORNING_INVEST)
          form.setFieldValue(["invest", "eveningInvest"], response.data.rows.EVENING_INVEST)
        })
        .catch((error) => { });
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  const submitForm = async (values) => {
    setLoading(true)
    try {
      var url = `${window.BACKEND_IP}invest/save`
      values.invest.USER_ID = sessionStorage.getItem("USER_ID");
      await axios
        .post(url, values.invest)
        .then((response) => {
          if (response.data) {
            messageApi.open({
              type: 'success',
              content: 'Saved Success!',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    getInvests();
  }, [])
  return (
    <div>
      <Divider style={{ margin: "10px 0" }} />
      <Row>
        <Col span={14}>
          <p style={{
            textAlign: 'left',
            marginLeft: "5px",
            marginTop: "-2px",
            fontSize: "25px",
            marginBottom: "0px"
          }}>Today Invest</p>
        </Col>
      </Row>
      <Divider style={{ margin: "10px 0" }} />
      {contextHolder}
      <Form
        form={form}
        name="form_item_path" layout="vertical" onFinish={submitForm} style={{ margin: "10px" }}>
        <Row gutter={24}>
          <Col span={24} style={{ marginBottom: "15px" }}>
            <Form.Item name={['invest', 'cashInvest']} label="Cash invest">
              <Input className="fontSize20" placeholder='ex: 1000' type="tel" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: "15px" }}>
            <Form.Item name={['invest', 'morningInvest']} label="Morning Invest" >
              <Input className="fontSize20" placeholder='ex: 2000' type="tel" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: "15px" }}>
            <Form.Item name={['invest', 'eveningInvest']} label="Evening Invest" >
              <Input className="fontSize20" placeholder='ex: 3000' type="tel" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: "15px" }}>
            <Button loading={loading} type="primary" htmlType="submit" style={{ width: "100%", textAlign: "center", backgroundColor: "#188f18", marginTop: "30px" }}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div >
  );
}

export default TodayInvest;
