// import './App.css';

import { Button, Card, Col, Divider, Form, Input, Row, message } from "antd";
import { useState } from "react";
import 'react-clock/dist/Clock.css';
import { useNavigate } from "react-router-dom";
import 'react-time-picker/dist/TimePicker.css';

const Login = (props) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const nav = useNavigate();
  const login = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${window.BACKEND_IP}user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userName: form.getFieldValue("userName"),
          password: form.getFieldValue("password")
        })
      });

      const data = await response.json();

      if (data.success) {
        console.log(data.settings, 'xxx');
        sessionStorage.setItem("token", "cgs-HH28882-636sdbbd0-hwyewye-svv1hdh-dhb9272-25519191");
        sessionStorage.setItem("IS_SUPER_ADMIN", data.rows.IS_SUPER_ADMIN);
        sessionStorage.setItem("USER_ID", data.rows.USER_ID);
        sessionStorage.setItem("NAME", data.rows.NAME);
        sessionStorage.setItem("morningTimeEnd", parseInt(data.settings.MORNING_HOUR_END * 60) + parseInt(data.settings.MORNING_MINUTES_END));
        sessionStorage.setItem("eveningTimeEnd", parseInt(data.settings.EVENING_HOUR_END * 60) + parseInt(data.settings.EVENING_MINUTES_END));

        if (data.rows.IS_SUPER_ADMIN == 1) {
          nav("/message-view");
        } else {
          nav("/message");
        }
      } else {
        messageApi.open({
          type: 'warning',
          content: 'Invalid Credentials',
          className: 'custom-class',
        });
      }

    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false)
    }
  };
  return (
    <div>
      {contextHolder}
      <Form
        form={form}
        onFinish={login}
        // ref={formRef}
        name="form_item_path" layout="vertical" style={{ margin: "10px" }}>
        <Card loading={loading}>
          <Row gutter={24} >

            <Col span={24} className="marginBottom-15px"  >
              <h3 style={{
                fontSize: "30px",
                letterSpacing: "5px"
              }}>Login</h3>
              <Divider />
            </Col>
            <Col span={24} className="marginBottom-15px"  >
              <Form.Item name={['userName']} label="User Name" rules={[{ required: true, message: 'Required!' }]}>
                <Input className="fontSize20" style={{ width: "100%" }} />
              </Form.Item>
              <br />
            </Col>
            <Col span={24} className="marginBottom-15px"  >
              <Form.Item name={['password']} label="Password" rules={[{ required: true, message: 'Required!' }]}>
                <Input.Password className="fontSize20" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={24} className="marginBottom-15px"  >
              <Button htmlType="submit" type="primary" className="loginBtn" >Login</Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div >
  );
}

export default Login;
