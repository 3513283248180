import {
  DoubleRightOutlined
} from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Form, Modal, Row } from 'antd';
import axios from 'axios';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import './Chat.css';
import TakeAPhoto from "./TakeAPhoto";

const SendImages = () => {
  const messagesEndRef = useRef(null)
  const [myImages, setMyImages] = useState([]);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [morningShowImages, setMorningShowImages] = useState(false);
  const [eveningShowImages, setEveningShowImages] = useState(false);
  const [exShowImages, setExShowImages] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [form] = Form.useForm();

  const cancelModel = async (e) => {
    setCameraOpen(false)
    // getImages();
    getImagesCount();
  }
  const searchData = async () => {
    try {
      const response = await axios.post(window.BACKEND_IP + "images/search", {
        USER_ID: sessionStorage.getItem("USER_ID"),
        DATE: form.getFieldValue("datePicker")
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMyImages(response.data.rows)
    } catch (error) {
      console.error('Error:', error);
      // Handle errors, such as setting an error state or displaying an error message
    }
  }
  const getImagesCount = async (e) => {
    setCameraOpen(false)
    // try {
    //   const response = await axios.post(window.BACKEND_IP + "images/countby/user", {
    //     USER_ID: sessionStorage.getItem("USER_ID"),
    //   }, {
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   });
    //   setMyImages(response.data.rows)
    // } catch (error) {
    //   console.error('Error:', error);
    //   // Handle errors, such as setting an error state or displaying an error message
    // }
  }
  const getImages = async (e) => {
    // setCameraOpen(false)
    try {
      setMorningShowImages(false)
      setEveningShowImages(false)
      setExShowImages(false)
      const response = await axios.post(window.BACKEND_IP + "images/by/user", {
        USER_ID: sessionStorage.getItem("USER_ID"),
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMyImages(response.data.rows)
    } catch (error) {
      console.error('Error:', error);
      // Handle errors, such as setting an error state or displaying an error message
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const openCamera = (e) => {
    e.preventDefault();
    setCameraOpen(true);
  };
  const showZoomImage = (image) => {
    setImageModalOpen(true)
    setImageUrl(image);
  };
  useEffect(() => {
    getImages()
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [myImages]);


  return (
    <div className="chat-container">
      <div className="chat-messages">
        <Modal
          title="View Image"
          open={imageModalOpen}
          onCancel={() => {
            setImageModalOpen(false)
          }}
          footer={[
            <Button key="back" onClick={() => {
              setImageModalOpen(false)
            }}>
              Cancel
            </Button>]}
        >
          <img src={imageUrl} style={{ width: "100%" }} />
        </Modal>
        <Row>
          <Form style={{ width: "100%" }}
            form={form}
          >
            <Col span={24} style={{ width: "100%" }}>
              Search by Date<br></br>
              <Form.Item name="datePicker" >
                <DatePicker style={{ width: "70%" }} onChange={(val) => {
                  setDateValue(moment(val).format("YYYY-MM-DD"))
                }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button type='primary'
                onClick={() => {
                  searchData()
                }}>Search</Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  getImages()
                }}>Cancel</Button>
            </Col>
          </Form>
          <Divider />

          <h2 style={{ textAlign: "left", width: "100%" }}>Morning Chits <span style={{ float: "right", marginRight: "20px" }}>{myImages.filter(element => element.EX == 0 & parseInt(moment(element.CREATED_DATE).format("H") * 60) + parseInt(moment(element.CREATED_DATE).format("m")) < sessionStorage.getItem("morningTimeEnd")).length}</span>
            <Button onClick={() => {
              setMorningShowImages(true)
            }}>Show Image</Button>

          </h2>
          <Divider />
          {morningShowImages ? myImages.map((images, index) => (
            // <div key={index} className={`message `} style={{ border: "3px solid #f3f3f3" }}>
            images.EX == 0 & parseInt(moment(images.CREATED_DATE).format("H") * 60) + parseInt(moment(images.CREATED_DATE).format("m")) < sessionStorage.getItem("morningTimeEnd") ?
              <Col span={6} key={index} style={{ border: "3px solid #f3f3f3" }}>
                <p style={{ textAlign: "left", fontSize: "11px" }}>Inv : {parseFloat(images.INVESTMENT).toFixed(2)} <span style={{ float: "right" }}>
                  {images.IS_SEEN == 0 ?
                    <DoubleRightOutlined />
                    : <DoubleRightOutlined style={{ color: "#00a4ff" }} />}</span></p>
                <img src={images.IMAGE} style={{ width: "100%" }} className="imageObjectFit" onClick={() => showZoomImage(images.IMAGE)} />
                <Row>
                  <Col span={24}>
                    <p style={{ textAlign: "center", margin: "0px" }}>{moment(images.CREATED_DATE).format("Y-MM-DD LTS")}   </p>
                  </Col>
                  {/* <Col span={6} style={{ paddingTop: "10px" }}>
                {images.IS_SEEN == 0 ?
                  <Popconfirm
                    title="Delete the Image"
                    description="Are you sure to delete this Image?"
                    onConfirm={() => removeImage(images.ID)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <CloseCircleOutlined style={{
                      fontSize: "25px"
                    }} />
                  </Popconfirm>
                  : ""}
              </Col> */}
                </Row>

                <div ref={messagesEndRef} />

                {/* </div> */}
              </Col>
              : ""
          )) : ""}
        </Row>
        <Row>
          <h2 style={{ textAlign: "left", width: "100%" }}>Evening Chits <span style={{ float: "right", marginRight: "20px" }}>{myImages.filter(element => element.EX == 0 & parseInt(moment(element.CREATED_DATE).format("H") * 60) + parseInt(moment(element.CREATED_DATE).format("m")) > sessionStorage.getItem("morningTimeEnd")).length}</span>
            <Button onClick={() => {
              setEveningShowImages(true)
            }}>Show Image</Button>
          </h2>
          <Divider />
          {eveningShowImages ? myImages.map((images, index) => (
            // <div key={index} className={`message `} style={{ border: "3px solid #f3f3f3" }}>
            images.EX == 0 & parseInt(moment(images.CREATED_DATE).format("H") * 60) + parseInt(moment(images.CREATED_DATE).format("m")) > sessionStorage.getItem("morningTimeEnd") ?
              <Col span={6} key={index} style={{ border: parseInt(moment(images.CREATED_DATE).format("H") * 60) + parseInt(moment(images.CREATED_DATE).format("m")) >= sessionStorage.getItem("eveningTimeEnd") ? "1px solid rgb(193 68 68)" : "3px solid #f3f3f3" }}>
                <p style={{ textAlign: "left", fontSize: "11px" }}>Inv : {parseFloat(images.INVESTMENT).toFixed(2)}<span style={{ float: "right" }}>
                  {images.IS_SEEN == 0 ?
                    <DoubleRightOutlined />
                    : <DoubleRightOutlined style={{ color: "#00a4ff" }} />}</span></p>
                <img src={images.IMAGE} style={{ width: "100%" }} className="imageObjectFit" onClick={() => showZoomImage(images.IMAGE)} />
                <Row>
                  <Col span={24}>
                    <p style={{ textAlign: "center", margin: "0px" }}>{moment(images.CREATED_DATE).format("Y-MM-DD LTS")} </p>
                  </Col>
                  {/* <Col span={6} style={{ paddingTop: "10px" }}>
                {images.IS_SEEN == 0 ?
                  <Popconfirm
                    title="Delete the Image"
                    description="Are you sure to delete this Image?"
                    onConfirm={() => removeImage(images.ID)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <CloseCircleOutlined style={{
                      fontSize: "25px"
                    }} />
                  </Popconfirm>
                  : ""}
              </Col> */}
                </Row>

                <div ref={messagesEndRef} />
              </Col>
              : ""
          )) : ""}
        </Row>
        <Row>
          <h2 style={{ textAlign: "left", width: "100%" }}>EX <span style={{ float: "right", marginRight: "20px" }}>{myImages.filter(element => element.EX == 1).length}</span>
            <Button onClick={() => {
              setExShowImages(true)
            }}>Show Image</Button>
          </h2>
          <Divider />
          {exShowImages ? myImages.map((images, index) => (
            // <div key={index} className={`message `} style={{ border: "3px solid #f3f3f3" }}>
            images.EX == 1 ?
              <Col span={6} key={index} style={{ border: parseInt(moment(images.CREATED_DATE).format("H") * 60) + parseInt(moment(images.CREATED_DATE).format("m")) >= sessionStorage.getItem("eveningTimeEnd") ? "1px solid rgb(193 68 68)" : "3px solid #f3f3f3" }}>
                <p style={{ textAlign: "left", fontSize: "11px" }}>Inv : {parseFloat(images.INVESTMENT).toFixed(2)}<span style={{ float: "right" }}>
                  {images.IS_SEEN == 0 ?
                    <DoubleRightOutlined />
                    : <DoubleRightOutlined style={{ color: "#00a4ff" }} />}</span></p>
                <img src={images.IMAGE} style={{ width: "100%" }} className="imageObjectFit" onClick={() => showZoomImage(images.IMAGE)} />
                <Row>
                  <Col span={24}>
                    <p style={{ textAlign: "center", margin: "0px" }}>{moment(images.CREATED_DATE).format("Y-MM-DD LTS")} </p>
                  </Col>
                  {/* <Col span={6} style={{ paddingTop: "10px" }}>
                {images.IS_SEEN == 0 ?
                  <Popconfirm
                    title="Delete the Image"
                    description="Are you sure to delete this Image?"
                    onConfirm={() => removeImage(images.ID)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <CloseCircleOutlined style={{
                      fontSize: "25px"
                    }} />
                  </Popconfirm>
                  : ""}
              </Col> */}
                </Row>

                <div ref={messagesEndRef} />
              </Col>
              : ""
          )) : ""}
        </Row>

      </div>
      <form className="chat-input-form">
        <img src='cam.png' alt="no" style={{ width: "80px", marginBottom: "60px" }} onClick={openCamera} />
      </form>
      {
        cameraOpen ?
          <TakeAPhoto
            visible={cameraOpen}
            cancelModel={cancelModel}
            getImages={getImages}
          />
          : ""
      }
    </div >
  );
};

export default SendImages;